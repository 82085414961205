import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '49, 27, 146',
		'primary-dark': '0, 0, 99',
		'accent': '70, 164, 62',
		'accent-plus': '0, 0, 0',
	},
});
